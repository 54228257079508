var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    ref: "autoinput",
    attrs: {
      "fetch-suggestions": _vm.querySearch,
      "trigger-on-focus": false,
      placeholder: _vm.placeholder,
      size: _vm.size,
      clearable: "",
    },
    on: {
      select: _vm.select,
      change: _vm.change,
      clear: _vm.clear,
      focus: _vm.onFocus,
      blur: _vm.onBlur,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(item.materialName) + " "),
              _c("span", { staticStyle: { "padding-left": "8px" } }, [
                _vm._v("|"),
              ]),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "addr" }, [
              _vm._v(_vm._s(item.materialType === 0 ? "图片" : "视频")),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.autocompleteValue1,
      callback: function ($$v) {
        _vm.autocompleteValue1 = $$v
      },
      expression: "autocompleteValue1",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }