"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _lang = _interopRequireDefault(require("@/lang"));
// import defaultSettings from '@/settings'

var title = 'Vets-View·门店终端播控系统';
function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));
  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));
    return "".concat(pageName, " - ").concat(title);
  }
  return "".concat(title);
}