var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c("div", { staticClass: "left-logo" }, [
      _c("img", { attrs: { src: require("../../img/logo.png"), alt: "" } }),
      _vm._v(" "),
      _vm.versions === "pro"
        ? _c("div", { staticClass: "left-msg" }, [
            _vm._v("Vets-View·门店终端播控系统\n          "),
            _c(
              "span",
              { staticStyle: { "font-size": "16px", color: "#999" } },
              [
                _vm._v(
                  "Vets Smart Choice · Business & Operation Support\n        System"
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.versions === "dev"
        ? _c(
            "div",
            {
              staticClass: "left-msg flex",
              staticStyle: { display: "flex", "flex-direction": "row" },
            },
            [
              _vm._v("\n      Vets-View·门店终端播控系统"),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("测试系统"),
              ]),
              _vm._v("\n          "),
              _c("span", {
                staticStyle: { "font-size": "16px", color: "#999" },
              }),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _c(
          "el-dropdown",
          { staticStyle: { cursor: "pointer" }, attrs: { trigger: "click" } },
          [
            _c(
              "div",
              { staticClass: "el-dropdown-link", attrs: { id: "right-bar" } },
              [
                _c("img", {
                  attrs: { src: require("../../img/avater.png"), alt: "" },
                }),
                _vm._v("\n        " + _vm._s(_vm.name) + "\n        "),
                _vm._v(" "),
                _c("el-divider", { attrs: { direction: "vertical" } }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "margin-right": "40px",
              cursor: "pointer",
              color: "#606266",
              "font-size": "14px",
            },
          },
          [
            _c("i", {
              staticClass: "el-icon-switch-button",
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: { "margin-left": "5px" },
                on: {
                  click: function ($event) {
                    return _vm.logout()
                  },
                },
              },
              [_vm._v("退出登录")]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }