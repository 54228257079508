var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _c("img", {
      staticClass: "bg-img",
      attrs: { src: require("../../img/login_bg.png"), alt: "" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "login-container" }, [
      _c(
        "div",
        { staticClass: "login_msg" },
        [
          _c(
            "el-row",
            { staticStyle: { width: "100%" } },
            [
              _c("el-col", { attrs: { span: 14 } }, [
                _c("div", { staticClass: "login-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../img/login_logo.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.versions === "pro"
                    ? _c("div", [_vm._v("Vets-View·门店终端播控系统")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.versions === "dev"
                    ? _c("div", [_vm._v("Vets-View·门店终端播控系统(测试)")])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("div", { staticClass: "from-title" }, [
                    _c("div", { staticClass: "welcom" }, [_vm._v("welcom")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "welcom-cn" }, [
                      _vm._v("欢迎登录"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "loginForm",
                      staticClass: "login-form",
                      attrs: {
                        model: _vm.loginForm,
                        autocomplete: "on",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "username" } },
                        [
                          _c("span", { staticClass: "svg-container" }, [
                            _c("i", { staticClass: "el-icon-user" }),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            ref: "username",
                            attrs: {
                              placeholder: _vm.$t("login.username"),
                              name: "username",
                              type: "text",
                              tabindex: "1",
                              autocomplete: "on",
                              maxlength: 20,
                            },
                            model: {
                              value: _vm.loginForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "username", $$v)
                              },
                              expression: "loginForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Caps lock is On",
                            placement: "right",
                            manual: "",
                          },
                          model: {
                            value: _vm.capsTooltip,
                            callback: function ($$v) {
                              _vm.capsTooltip = $$v
                            },
                            expression: "capsTooltip",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password" } },
                            [
                              _c("span", { staticClass: "svg-container" }, [
                                _c("i", { staticClass: "el-icon-lock" }),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                key: _vm.passwordType,
                                ref: "password",
                                attrs: {
                                  type: _vm.passwordType,
                                  placeholder: _vm.$t("login.password"),
                                  name: "password",
                                  tabindex: "2",
                                  autocomplete: "on",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.capsTooltip = false
                                  },
                                },
                                nativeOn: {
                                  keyup: [
                                    function ($event) {
                                      return _vm.checkCapslock($event)
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleLogin($event)
                                    },
                                  ],
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "show-pwd",
                                  on: { click: _vm.showPwd },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class":
                                        _vm.passwordType === "password"
                                          ? "eye"
                                          : "eye-open",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "login_btn",
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { loading: _vm.loading },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin($event)
                            },
                          },
                        },
                        [_vm._v("\n              立即登录\n            ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banquan" }, [
      _vm._v("版权所有© 南京瀚星信息科技有限公司 备案号\n      "),
      _c(
        "a",
        { attrs: { href: "http://beian.miit.gov.cn/", target: "blank" } },
        [_vm._v("苏ICP备 19027302号-3")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }