var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "common-table-filter-box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.searchForm, "label-width": "80px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 45 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入门店名称查询",
                            },
                            model: {
                              value: _vm.searchForm.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "companyName", $$v)
                              },
                              expression: "searchForm.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册账号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入注册账号查询",
                            },
                            model: {
                              value: _vm.searchForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "mobile", $$v)
                              },
                              expression: "searchForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备SN" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入设备SN码查询",
                            },
                            model: {
                              value: _vm.searchForm.snCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "snCode", $$v)
                              },
                              expression: "searchForm.snCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分屏类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.screenType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "screenType", $$v)
                                },
                                expression: "searchForm.screenType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "全屏", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "双屏A", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "双屏B", value: 5 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "双屏C", value: 6 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "三屏A", value: 2 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "三屏B", value: 3 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "三屏C", value: 4 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开机状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.online,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "online", $$v)
                                },
                                expression: "searchForm.online",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "开机", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "关机", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.playStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "playStatus", $$v)
                                },
                                expression: "searchForm.playStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "播放", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "未播放", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备区域" } },
                        [
                          _c("common-cascader", {
                            attrs: {
                              options: _vm.app.district,
                              props: _vm.optionProps,
                            },
                            model: {
                              value: _vm.searchForm.district,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "district", $$v)
                              },
                              expression: "searchForm.district",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "绑定时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              editable: false,
                              type: "daterange",
                              align: "center",
                              "unlink-panels": "",
                              "range-separator": "->",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "value-format": "yyyy-MM-dd",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.time,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "time", $$v)
                              },
                              expression: "searchForm.time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onSearch()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.resetSearch()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-table-box pb-10" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tabLoad,
                      expression: "tabLoad",
                    },
                  ],
                  ref: "commonElTable",
                  attrs: {
                    data: _vm.tableList,
                    "header-cell-class-name": "header-cell-class-name",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "snCode", label: "设备SN" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "companyName", label: "门店名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "mobile", label: "注册账号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "设备区域" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.provinceName) +
                                  " - " +
                                  _vm._s(scope.row.cityName) +
                                  " - " +
                                  _vm._s(scope.row.areaName) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分屏类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    [
                                      "全屏",
                                      "双屏A",
                                      "三屏A",
                                      "三屏B",
                                      "三屏C",
                                      "双屏B",
                                      "双屏C",
                                    ][scope.row.screenType]
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "开机状态",
                      align: "center",
                      width: "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    scope.row.online === 0
                                      ? "#3CBC67"
                                      : "#C73E12",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      ["已开机", "已关机", "已离线"][
                                        scope.row.online
                                      ]
                                    )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "播放状态",
                      align: "center",
                      width: "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    scope.row.playStatus === 0
                                      ? "#3CBC67"
                                      : "#C73E12",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      ["已播放", "未播放"][scope.row.playStatus]
                                    )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bindTime",
                      label: "绑定时间",
                      width: "160px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "right", width: "250px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.screenManager(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 屏幕管理 ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mangerPlay(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 播放管理 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              align: "center",
              total: _vm.total,
              page: _vm.searchForm.page,
              limit: _vm.searchForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "limit", $event)
              },
              pagination: _vm.pagination,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_screenManager, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_screenManager = $event
            },
          },
        },
        [
          _c("div", { staticClass: "common-detail-box" }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "title" }, [_vm._v("屏幕管理")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-box description-box" }, [
              _c("div", { staticClass: "big_screen flex-c-c-c" }, [
                _c("img", {
                  staticStyle: { width: "135px" },
                  attrs: {
                    src:
                      "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/screen/big" +
                      _vm.screen.index +
                      ".png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", [_vm._v("当前屏显模式：" + _vm._s(_vm.screen.type))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "small_screen" },
                _vm._l(_vm.smallList, function (small, small_index) {
                  return _c(
                    "div",
                    { key: small_index },
                    [
                      _c("div", { staticClass: "small_item flex-r-sb-c" }, [
                        _vm.screen.index === small.index
                          ? _c("img", {
                              attrs: {
                                src:
                                  "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/screen/smll" +
                                  small.index +
                                  "-1.png",
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src:
                                  "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/screen/smll" +
                                  small.index +
                                  "-0.png",
                                alt: "",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: small.check
                              ? "screen_name_active "
                              : "screen_name",
                          },
                          [_vm._v(_vm._s(small.name))]
                        ),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "22px" } }, [
                          _c("i", {
                            staticClass: "el-icon-success",
                            style: {
                              color: small.check ? "#0768AB" : "#E8E8E8",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.chooseScreen(small)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.save } },
                  [_vm._v(" 保存设置 ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-buttons-box flex-r-sb-c" }, [
      _c("div", { staticClass: "title" }, [_vm._v("播控管理")]),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }