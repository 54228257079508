var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        {
          staticClass: "common-table-filter-box",
          staticStyle: {
            background: "none",
            "padding-left": "0px",
            "padding-top": "0px",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-r-s-c",
              staticStyle: { "margin-top": "18px" },
            },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: {
                    column: 4,
                    size: "medium",
                    border: "",
                    "label-class-name": "myDescriptionsLabel5",
                    "content-class-name": "myDescriptionsContent5",
                  },
                },
                [
                  _c("el-descriptions-item", [
                    _c(
                      "div",
                      {
                        staticClass: "top_item flex-r-s-s",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_02.png",
                            alt: "",
                          },
                        }),
                        _vm._v("绑定医院\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "top_msg" }, [
                      _vm._v(_vm._s(_vm.info.companyName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", [
                    _c(
                      "div",
                      {
                        staticClass: "top_item flex-r-s-s",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_03.png",
                            alt: "",
                          },
                        }),
                        _vm._v("注册账号\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "top_msg" }, [
                      _vm._v(_vm._s(_vm.info.mobile)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", [
                    _c(
                      "div",
                      {
                        staticClass: "top_item flex-r-s-s",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_04.png",
                            alt: "",
                          },
                        }),
                        _vm._v("设备编码\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "top_msg" }, [
                      _vm._v(_vm._s(_vm.info.snCode)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", [
                    _c(
                      "div",
                      {
                        staticClass: "top_item flex-r-s-s",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_05.png",
                            alt: "",
                          },
                        }),
                        _vm._v("屏幕类型\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "top_msg" }, [
                      _vm._v(
                        _vm._s(
                          ["全屏", "双屏A", "三屏A", "三屏B", "三屏C"][
                            _vm.info.screenType
                          ]
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "common-table-box pb-10" }, [
        _c("div", { staticClass: "top-buttons-box flex-r-sb-c" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 播控管理")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    color: "#0768AB",
                    border: "1px solid #0768AB",
                    padding: "10px 7px",
                  },
                  attrs: { type: "text", icon: "el-icon-plus", plain: "" },
                  on: { click: _vm.addPlay },
                },
                [_vm._v("创建播放\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    color: "#0768AB",
                    border: "1px solid #0768AB",
                    padding: "10px 7px",
                  },
                  attrs: { type: "text", icon: "el-icon-plus", plain: "" },
                  on: { click: _vm.addNewBroadcast },
                },
                [_vm._v("创建广播\n        ")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "图片或视频", name: "1" },
                }),
                _vm._v(" "),
                _c("el-tab-pane", { attrs: { label: "广播", name: "2" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                ref: "commonElTable",
                attrs: {
                  data: _vm.resourceResponseList,
                  "header-cell-class-name": "header-cell-class-name",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "30",
                    align: "center",
                    "reserve-selection": true,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "sort", label: "序号", width: "60px" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "resourceName", label: "资源名称" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "资源类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  ["图片", "视频", "广播"][
                                    scope.row.resourceType
                                  ]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "playArea", label: "播放区域" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  ["1-A-01", "1-A-02", "1-A-03"][
                                    scope.row.playArea
                                  ]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "播放状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  [
                                    "正常播放",
                                    "暂停播放",
                                    "到期停播",
                                    "满次停播",
                                  ][scope.row.playStatus]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "播放类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  ["循环播放", "整点播放", "半点播放"][
                                    scope.row.playType
                                  ]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "sumTimes", label: "累计播放次数" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "addTime", label: "添加时间" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "createUserName", label: "添加人" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "right", width: "300px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              scope.row.playStatus > 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rePlay(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 重新播放 ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rePlay(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 暂停播放 ")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openCopy(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 复制方案 ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeR(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("播放设置 ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 删除资源 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                align: "center",
                total: _vm.total,
                page: _vm.searchForm.page,
                limit: _vm.searchForm.limit,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "limit", $event)
                },
                pagination: _vm.pagination,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottom_btn", staticStyle: { margin: "60px 0" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#0768AB",
                      border: "1px solid #0768AB",
                      padding: "10px 7px",
                    },
                    attrs: {
                      type: "text",
                      plain: "",
                      icon: "el-icon-video-pause",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectControl(1)
                      },
                    },
                  },
                  [_vm._v(" 全部暂停 ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#D1531C",
                      border: "1px solid #D1531C",
                      padding: "10px 7px",
                    },
                    attrs: { type: "text", plain: "", icon: "el-icon-delete" },
                    on: {
                      click: function ($event) {
                        return _vm.selectControl(0)
                      },
                    },
                  },
                  [_vm._v(" 全部播放 ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#0768AB",
                      border: "1px solid #0768AB",
                      padding: "10px 7px",
                    },
                    attrs: {
                      type: "text",
                      plain: "",
                      icon: "el-icon-copy-document",
                    },
                    on: { click: _vm.copyAll },
                  },
                  [_vm._v(" 全部复制 ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#D1531C",
                      border: "1px solid #D1531C",
                      padding: "10px 7px",
                    },
                    attrs: { type: "text", plain: "", icon: "el-icon-delete" },
                    on: { click: _vm.selectDel },
                  },
                  [_vm._v(" 全部删除 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_editPlay, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_editPlay = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("播放设置")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.resourceInfo, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "资源名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "设置资源名称",
                            },
                            model: {
                              value: _vm.resourceInfo.resourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.resourceInfo, "resourceName", $$v)
                              },
                              expression: "resourceInfo.resourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "资源类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.resourceInfo.resourceType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.resourceInfo,
                                    "resourceType",
                                    $$v
                                  )
                                },
                                expression: "resourceInfo.resourceType",
                              },
                            },
                            [
                              _vm.resourceInfo.resourceType === 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("图片")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.resourceInfo.resourceType === 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("视频")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.resourceInfo.resourceType === 2
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_vm._v("广播")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "可用范围" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.resourceInfo.ranges,
                                callback: function ($$v) {
                                  _vm.$set(_vm.resourceInfo, "ranges", $$v)
                                },
                                expression: "resourceInfo.ranges",
                              },
                            },
                            [
                              _vm.resourceInfo.ranges === 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("公共素材")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.resourceInfo.ranges === 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("客户素材")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放区域" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.resourceInfo.playArea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.resourceInfo, "playArea", $$v)
                                },
                                expression: "resourceInfo.playArea",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _c("div", [_vm._v("1-A-01")]),
                              ]),
                              _vm._v(" "),
                              _vm.info.screenType > 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_c("div", [_vm._v("1-A-02")])]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.info.screenType > 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_c("div", [_vm._v("1-A-03")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.resourceInfo.playType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.resourceInfo, "playType", $$v)
                                },
                                expression: "resourceInfo.playType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("循环播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("整点播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("半点播放"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放次数" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.resourceInfo.playMaxSwitch,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.resourceInfo,
                                    "playMaxSwitch",
                                    $$v
                                  )
                                },
                                expression: "resourceInfo.playMaxSwitch",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("限制"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.resourceInfo.playMaxSwitch === 1
                            ? _c("el-input", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: {
                                  clearable: "",
                                  placeholder: "设置播放次数",
                                },
                                model: {
                                  value: _vm.resourceInfo.playMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.resourceInfo, "playMax", $$v)
                                  },
                                  expression: "resourceInfo.playMax",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放限期" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.resourceInfo.playTimeSwitch,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.resourceInfo,
                                    "playTimeSwitch",
                                    $$v
                                  )
                                },
                                expression: "resourceInfo.playTimeSwitch",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("限制"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.resourceInfo.playTimeSwitch === 1
                            ? _c("el-date-picker", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "设置到期时间",
                                },
                                model: {
                                  value: _vm.resourceInfo.playEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.resourceInfo,
                                      "playEndTime",
                                      $$v
                                    )
                                  },
                                  expression: "resourceInfo.playEndTime",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.resourceInfo.resourceType === 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "播放限期" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "40%",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入播放限期",
                                },
                                model: {
                                  value: _vm.resourceInfo.picShowTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.resourceInfo,
                                      "picShowTime",
                                      $$v
                                    )
                                  },
                                  expression: "resourceInfo.picShowTime",
                                },
                              }),
                              _vm._v(" 秒\n          "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入排序" },
                            model: {
                              value: _vm.resourceInfo.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.resourceInfo, "sort", $$v)
                              },
                              expression: "resourceInfo.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.changePlay },
                    },
                    [_vm._v(" 保存设置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_addPlay, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_addPlay = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("创建播放")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.addPlayFrom, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "资源名称" } },
                        [
                          _c("effectiveAuto", {
                            attrs: { placeholder: "请输入资源名称查询" },
                            on: { select: _vm.selectPlay },
                            model: {
                              value: _vm.addPlayFrom.resourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addPlayFrom, "resourceName", $$v)
                              },
                              expression: "addPlayFrom.resourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "资源类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addPlayFrom.resourceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addPlayFrom, "resourceType", $$v)
                                },
                                expression: "addPlayFrom.resourceType",
                              },
                            },
                            [
                              _vm.addPlayFrom.resourceType === 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("图片")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addPlayFrom.resourceType === 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("视频")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "可用范围" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addPlayFrom.ranges,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addPlayFrom, "ranges", $$v)
                                },
                                expression: "addPlayFrom.ranges",
                              },
                            },
                            [
                              _vm.addPlayFrom.ranges === 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("公共素材")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addPlayFrom.ranges === 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("运营素材")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addPlayFrom.ranges === 2
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_vm._v("医院素材")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                slot: "label",
                                placement: "right",
                                width: "160",
                                trigger: "click",
                              },
                              slot: "label",
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "135px" },
                                attrs: {
                                  src:
                                    "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/screen/big" +
                                    (_vm.info.screenType * 1 + 1) +
                                    ".png",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v("播放区域"),
                                  _c("i", { staticClass: "el-icon-question" }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addPlayFrom.playArea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addPlayFrom, "playArea", $$v)
                                },
                                expression: "addPlayFrom.playArea",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _c("div", [_vm._v("1-A-01")]),
                              ]),
                              _vm._v(" "),
                              _vm.info.screenType > 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_c("div", [_vm._v("1-A-02")])]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.info.screenType > 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_c("div", [_vm._v("1-A-03")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addPlayFrom.playType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addPlayFrom, "playType", $$v)
                                },
                                expression: "addPlayFrom.playType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("循环播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("整点播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("半点播放"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放次数" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addPlayFrom.playMaxSwitch,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addPlayFrom,
                                    "playMaxSwitch",
                                    $$v
                                  )
                                },
                                expression: "addPlayFrom.playMaxSwitch",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("限制"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.addPlayFrom.playMaxSwitch === 1
                            ? _c("el-input", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: {
                                  clearable: "",
                                  placeholder: "设置播放次数",
                                },
                                model: {
                                  value: _vm.addPlayFrom.playMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addPlayFrom, "playMax", $$v)
                                  },
                                  expression: "addPlayFrom.playMax",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放限期" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addPlayFrom.playTimeSwitch,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addPlayFrom,
                                    "playTimeSwitch",
                                    $$v
                                  )
                                },
                                expression: "addPlayFrom.playTimeSwitch",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("限制"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.addPlayFrom.playTimeSwitch === 1
                            ? _c("el-date-picker", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "设置到期时间",
                                },
                                model: {
                                  value: _vm.addPlayFrom.playEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addPlayFrom,
                                      "playEndTime",
                                      $$v
                                    )
                                  },
                                  expression: "addPlayFrom.playEndTime",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.addPlayFrom.resourceType === 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "播放时长" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "40%",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入播放限期",
                                },
                                model: {
                                  value: _vm.addPlayFrom.picShowTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addPlayFrom,
                                      "picShowTime",
                                      $$v
                                    )
                                  },
                                  expression: "addPlayFrom.picShowTime",
                                },
                              }),
                              _vm._v(" 秒\n          "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入排序" },
                            model: {
                              value: _vm.addPlayFrom.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.addPlayFrom, "sort", $$v)
                              },
                              expression: "addPlayFrom.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addNewPlay },
                    },
                    [_vm._v(" 保存设置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_addBroadcast, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_addBroadcast = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.addBType === 0 ? "创建" : "设置") + "广播"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.addBform, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "广播标题" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "79%" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入广播标题",
                            },
                            model: {
                              value: _vm.addBform.resourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addBform, "resourceName", $$v)
                              },
                              expression: "addBform.resourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addBform.playType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addBform, "playType", $$v)
                                },
                                expression: "addBform.playType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("循环播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("整点播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("半点播放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 3 } }, [
                                _vm._v("固定时间播放"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.addBform.playType === 3
                            ? _c("el-time-picker", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: { placeholder: "请选择具体时间" },
                                model: {
                                  value: _vm.addBform.playTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addBform, "playTime", $$v)
                                  },
                                  expression: "addBform.playTime",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放次数" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addBform.playMaxSwitch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addBform, "playMaxSwitch", $$v)
                                },
                                expression: "addBform.playMaxSwitch",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("限制"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.addBform.playMaxSwitch === 1
                            ? _c("el-input", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: {
                                  clearable: "",
                                  placeholder: "设置播放次数",
                                },
                                model: {
                                  value: _vm.addBform.playMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addBform, "playMax", $$v)
                                  },
                                  expression: "addBform.playMax",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放限期" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addBform.playTimeSwitch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addBform, "playTimeSwitch", $$v)
                                },
                                expression: "addBform.playTimeSwitch",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("限制"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.addBform.playTimeSwitch === 1
                            ? _c("el-date-picker", {
                                staticStyle: {
                                  width: "80%",
                                  "margin-top": "14px",
                                },
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "设置到期时间",
                                },
                                model: {
                                  value: _vm.addBform.playEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addBform, "playEndTime", $$v)
                                  },
                                  expression: "addBform.playEndTime",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "文字颜色" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addBform.textColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addBform, "textColor", $$v)
                                },
                                expression: "addBform.textColor",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "#FFFFFF" } },
                                [_vm._v("白色")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "#000000" } },
                                [_vm._v("黑色")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "背景颜色" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addBform.backColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addBform, "backColor", $$v)
                                },
                                expression: "addBform.backColor",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "#FFFFFF" } },
                                [_vm._v("白色")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "#D1531C" } },
                                [_vm._v("橙色")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "#0768AB" } },
                                [_vm._v("蓝色")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放内容" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              maxlength: "300",
                              "show-word-limit": "",
                              clearable: "",
                              placeholder: "请输入播放内容，字数不超过300字",
                            },
                            model: {
                              value: _vm.addBform.playContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.addBform, "playContent", $$v)
                              },
                              expression: "addBform.playContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _vm.addBType === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addNweB },
                        },
                        [_vm._v(" 保存设置 ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.addBType === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.changeNweB },
                        },
                        [_vm._v(" 保存设置 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_copyBroadcast, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_copyBroadcast = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("复制播放")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.searchForm, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _vm.copyType === 1
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "播放资源" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "show_source flex-r-s-c" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_01.png",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "source_info" }, [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(_vm.copyInfo.resourceName)
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "资源名称" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [_vm._v(_vm._s(_vm.copyInfo.resourceName))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "资源类型" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        ["图片", "视频", "广播"][
                                          _vm.copyInfo.resourceType
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "开放类型" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        ["公用素材", "客户素材"][
                                          _vm.copyInfo.ranges
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "播放区域" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [_vm._v(_vm._s(_vm.copyInfo.playArea))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "播放方式" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        ["循环播放", "整点播放", "半点播放"][
                                          _vm.copyInfo.playType
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "播放次数" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.copyInfo.sumTimes
                                          ? _vm.copyInfo.sumTimes
                                          : "不限"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "播放限期" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addBroadcast_info" },
                                  [_vm._v(_vm._s(_vm.copyInfo.playEndTime))]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.copyType === 2
                        ? _c("div", { staticClass: "common-table-box" }, [
                            _c(
                              "div",
                              { staticClass: "table" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "commonElTable2",
                                    attrs: {
                                      data: _vm.copytableList,
                                      "header-cell-class-name":
                                        "header-cell-class-name",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "index", label: "序号" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "resourceName",
                                        label: "资源名称",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "资源类型" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        [
                                                          "图片",
                                                          "视频",
                                                          "广播",
                                                        ][
                                                          scope.row.resourceType
                                                        ]
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        310586751
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "playArea",
                                        label: "播放区域",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "subtitle",
                          staticStyle: { "margin-top": "44px" },
                        },
                        [_vm._v("复制方案到以下设备")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "医院信息" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请输入医院名称/注册账号查询",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.remoteMethod,
                              },
                              on: { change: _vm.changeuserMobile },
                              model: {
                                value: _vm.userMobile,
                                callback: function ($$v) {
                                  _vm.userMobile = $$v
                                },
                                expression: "userMobile",
                              },
                            },
                            _vm._l(_vm.userList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.mobile,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.userId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.companyName) +
                                          "-" +
                                          _vm._s(item.mobile)
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "可选设备" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.radiouser,
                                callback: function ($$v) {
                                  _vm.radiouser = $$v
                                },
                                expression: "radiouser",
                              },
                            },
                            _vm._l(_vm.userEquipmentList, function (item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.id,
                                  staticStyle: {
                                    width: "100%",
                                    "margin-left": "0",
                                    "margin-bottom": "10px",
                                  },
                                  attrs: { label: item.id, border: "" },
                                },
                                [_vm._v(_vm._s(item.snCode))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _vm.copyType === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.copySingle },
                        },
                        [_vm._v(" 立即复制 ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.copyType === 2
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.copyMore },
                        },
                        [_vm._v(" 立即复制 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex-r-s-s",
        staticStyle: {
          "font-size": "16px",
          "font-weight": "700",
          color: "#333",
          "line-height": "20px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "20px", height: "20px", "margin-right": "5px" },
          attrs: {
            src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_01.png",
            alt: "",
          },
        }),
        _vm._v("设备信息\n    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }