"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CSRF = CSRF;
exports.category = category;
exports.district = district;
exports.district2 = district2;
exports.storeRecord = storeRecord;
exports.sysDownload_list = sysDownload_list;
exports.sysOrg_tree = sysOrg_tree;
exports.tags = tags;
exports.typeList = typeList;
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
// import request from '@/utils/request'

// 获取省市区
function district() {
  return (0, _bossRequest.default)({
    url: 'sysRegion/list',
    method: 'GET'
  });
}

// 获取省市
function district2() {
  return (0, _bossRequest.default)({
    url: 'sysRegion/getcity',
    method: 'GET'
  });
}

// CSRF
function CSRF(data) {
  return (0, _bossRequest.default)({
    url: 'sysSubToken/getToken?sessionid=' + data,
    method: 'GET'
  });
}

// 根据字典类型获取列表（name、value）
function typeList(data) {
  return (0, _bossRequest.default)({
    url: 'sysData/list?dictType=' + data,
    method: 'GET'
  });
}
// 商品分类(全部)
function category() {
  return (0, _bossRequest.default)({
    url: 'productCategory/all',
    method: 'get'
  });
}

// 全部仓库列表
function storeRecord(data) {
  return (0, _bossRequest.default)({
    url: 'storeRecord/all',
    method: 'post',
    data: data
  });
}

// 全部标签列表
function tags(data) {
  return (0, _bossRequest.default)({
    url: 'productMng/tags?name=' + data,
    method: 'get'
  });
}

// 全部组织架构树结构
function sysOrg_tree() {
  return (0, _bossRequest.default)({
    url: 'sysOrg/tree',
    method: 'get'
  });
}

// 下载队列列表
function sysDownload_list(data) {
  return (0, _bossRequest.default)({
    url: 'sysDownload/list',
    method: 'post',
    data: data
  });
}