"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SysGroupPage = SysGroupPage;
exports.addGroupLeadPerformance = addGroupLeadPerformance;
exports.addGroupMemberPerformance = addGroupMemberPerformance;
exports.auditInfo = auditInfo;
exports.auditOcr = auditOcr;
exports.bandWeixinCode = bandWeixinCode;
exports.createGroup = createGroup;
exports.districts = districts;
exports.exportExtendDetail = exportExtendDetail;
exports.exportGroupCommissionDetail = exportGroupCommissionDetail;
exports.exportGroupLeadPerformance = exportGroupLeadPerformance;
exports.exportMaintainSalesStatistics = exportMaintainSalesStatistics;
exports.exportManagerDetail = exportManagerDetail;
exports.extendDetailPage = extendDetailPage;
exports.fastapply = fastapply;
exports.fastocr = fastocr;
exports.fastreg = fastreg;
exports.getGroupLeadPerformance = getGroupLeadPerformance;
exports.getGroupMemberPerformance = getGroupMemberPerformance;
exports.getGroupMemberPerformanceRuleByGroupId = getGroupMemberPerformanceRuleByGroupId;
exports.getGroupPerformancePage = getGroupPerformancePage;
exports.getGroupPerformanceRuleByGroup = getGroupPerformanceRuleByGroup;
exports.getInfo = getInfo;
exports.getMenu = getMenu;
exports.getSysGroupDetailByGroupId = getSysGroupDetailByGroupId;
exports.getSysGroupDictionary = getSysGroupDictionary;
exports.groupCommissionDetailPage = groupCommissionDetailPage;
exports.groupDictionary = groupDictionary;
exports.groupUserDictionary = groupUserDictionary;
exports.login = login;
exports.logout = logout;
exports.maintainSalesStatistics = maintainSalesStatistics;
exports.mallUserDetail = mallUserDetail;
exports.managerDetailPage = managerDetailPage;
exports.modifyMobile = modifyMobile;
exports.outSign_detail = outSign_detail;
exports.outSign_list = outSign_list;
exports.outSign_listExport = outSign_listExport;
exports.personOcr = personOcr;
exports.phoneCheck = phoneCheck;
exports.provinceList = provinceList;
exports.removeGroup = removeGroup;
exports.searchForAdd = searchForAdd;
exports.signGet = signGet;
exports.signSet = signSet;
exports.supplierByPhone = supplierByPhone;
exports.supplier_add = supplier_add;
exports.supplier_list = supplier_list;
exports.supplier_update = supplier_update;
exports.toUserList = toUserList;
exports.updateGroup = updateGroup;
exports.updateGroupMemberPerformanceRule = updateGroupMemberPerformanceRule;
exports.updateGroupPerformanceRule = updateGroupPerformanceRule;
exports.userDetail = userDetail;
exports.userECharts = userECharts;
exports.userEChartsScale = userEChartsScale;
exports.userIndustry = userIndustry;
exports.userLoginECharts = userLoginECharts;
exports.userManagerDetail = userManagerDetail;
exports.userManagerList = userManagerList;
exports.userOrderECharts = userOrderECharts;
exports.userPassword = userPassword;
exports.userUpdate = userUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
var _bankendView = _interopRequireDefault(require("@/utils/bankendView"));
function login(data) {
  return (0, _bossRequest.default)({
    url: 'sysLogin/login',
    method: 'post',
    data: data
  });
}
function getInfo() {
  return (0, _bossRequest.default)({
    url: 'sys/temp/bossLoginRoles',
    method: 'POST'
  });
}
function getMenu() {
  return (0, _bossRequest.default)({
    url: 'sysMenu/nav',
    method: 'get'
  });
}
function logout() {
  return (0, _bossRequest.default)({
    url: 'sysLogin/logout',
    method: 'post'
  });
}
function userDetail() {
  return (0, _bossRequest.default)({
    url: 'sysUser/info',
    method: 'get'
  });
}

// 用户x修改密码
function userPassword(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/password',
    method: 'POST',
    data: data
  });
}

// 用户身份
function userIndustry() {
  return (0, _bossRequest.default)({
    url: 'sysCode/industry',
    method: 'get'
  });
}

// 用户数据图表
function userECharts(data) {
  return (0, _bossRequest.default)({
    url: 'user/userECharts',
    method: 'post',
    data: data
  });
}

// 用户数据统计
function userEChartsScale(data) {
  return (0, _bossRequest.default)({
    url: 'user/userEChartsScale',
    method: 'post',
    data: data
  });
}

// 用户登录日志统计
function userLoginECharts(data) {
  return (0, _bossRequest.default)({
    url: 'user/loginlog/eCharts',
    method: 'post',
    data: data
  });
}
// 用户登录日志统计
function userOrderECharts(data) {
  return (0, _bossRequest.default)({
    url: 'user/orderlog/userOrderEChartsDays',
    method: 'post',
    data: data
  });
}

// 用户OCR企业认证
function fastocr(data) {
  return (0, _request.default)({
    url: 'hx.baoxian/boss/login/companyOCR',
    method: 'post',
    data: data
  });
}

// 导出用户列表
function toUserList(data) {
  return (0, _bossRequest.default)({
    url: 'user/exportUserInfo?startDate=' + data.startDate + '&endDate=' + data.endDate,
    method: 'get'
  });
}

// 省市区总表
function districts() {
  return (0, _request.default)({
    url: 'hx.baoxian/common/district',
    method: 'get'
  });
}

// 省 列表
function provinceList() {
  return (0, _request.default)({
    url: 'hx.baoxian/common/district/province',
    method: 'get'
  });
}

// 企业微信绑定
function bandWeixinCode(data) {
  return (0, _bossRequest.default)({
    url: 'weixin/api/getuserinfo?state=' + data.state + '&code=' + data.code,
    method: 'get'
  });
}

// ==================OMS2.0======

// 用户详情
function userManagerDetail(data) {
  return (0, _bossRequest.default)({
    url: '/user/detail?supplierId=' + data,
    method: 'GET'
  });
}

// 商品概况手机-供应商查询
function supplierByPhone(data) {
  return (0, _bossRequest.default)({
    url: 'user/supplierByPhone?phone=' + data,
    method: 'GET'
  });
}

// 供应商列表(通过公司名称查询)
function searchForAdd(data) {
  return (0, _bossRequest.default)({
    url: 'user/searchForAdd?searchText=' + data,
    method: 'GET'
  });
}

// 更新供应商修改
function supplier_update(data) {
  return (0, _bossRequest.default)({
    url: 'user/update',
    method: 'post',
    data: data
  });
}

// 供应商列表
function supplier_list(data) {
  return (0, _bossRequest.default)({
    url: 'user/search',
    method: 'post',
    data: data
  });
}

// 供应商新增
function supplier_add(data) {
  return (0, _bossRequest.default)({
    url: 'user/add',
    method: 'post',
    data: data
  });
}

// 用户列表
function userManagerList(data) {
  return (0, _bossRequest.default)({
    url: 'user/managerList',
    method: 'post',
    data: data
  });
}

// 用户上传审核信息
function auditInfo(data) {
  return (0, _bossRequest.default)({
    url: 'user/updateGongshangInfo',
    method: 'post',
    data: data
  });
}

// 商城用户详情(supplierId替代userId)
function mallUserDetail(data) {
  return (0, _bossRequest.default)({
    url: '/user/detail?supplierId=' + data,
    method: 'GET'
  });
}
// 更新用户信息
function userUpdate(data) {
  return (0, _bossRequest.default)({
    url: '/user/update',
    method: 'post',
    data: data
  });
}

// 用户快捷注册
function fastreg(data) {
  return (0, _bossRequest.default)({
    url: '/user/reg',
    method: 'post',
    data: data
  });
}

// 用户企业商城入驻申请
function fastapply(data) {
  return (0, _bossRequest.default)({
    url: '/user/shopApply',
    method: 'post',
    data: data
  });
}

// 用户审核ocr
function auditOcr(data) {
  return (0, _request.default)({
    url: 'hx.baoxian/common/ocrOms?orcUrl=' + data,
    method: 'get'
  });
}
// 识别身份证获取身份信息
function personOcr(data) {
  return (0, _bankendView.default)({
    url: 'admin/ocr/idCard?fileUrl=' + data,
    method: 'get'
  });
}

// 分页查询商铺列表
function phoneCheck(data) {
  return (0, _bossRequest.default)({
    url: 'user/applySuccessShops',
    method: 'post',
    data: data
  });
}

// 修改用户手机号
function modifyMobile(data) {
  return (0, _bossRequest.default)({
    url: 'user/modifyMobile',
    method: 'post',
    data: data
  });
}

/**
 * @位置签到
 * kadiam 2023-1-5
*/
// 获取用户签到位置
function signGet(data) {
  return (0, _bossRequest.default)({
    url: 'user/signGet',
    method: 'post',
    data: data
  });
}
// 用户签到设置
function signSet(data) {
  return (0, _bossRequest.default)({
    url: 'user/signSet',
    method: 'post',
    data: data
  });
}

/**
 * @位置签到外勤
 * kadiam 2023-1-6
*/
// 外勤签到列表
function outSign_list(data) {
  return (0, _bossRequest.default)({
    url: 'outSign/list',
    method: 'post',
    data: data
  });
}
// 外勤签到列表 导出
function outSign_listExport(data) {
  return (0, _bossRequest.default)({
    url: 'outSign/listExport',
    method: 'post',
    data: data
  });
}
// 外勤签到详情
function outSign_detail(data) {
  return (0, _bossRequest.default)({
    url: 'outSign/detail/' + data,
    method: 'get'
  });
}
/**
 * @OA团队管理
 * kadiam 2023-1-10
*/
// 团队管理分页列表
function SysGroupPage(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/SysGroupPage',
    method: 'post',
    data: data
  });
}
// 查询团队字典表
function getSysGroupDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/getSysGroupDictionary',
    method: 'get',
    data: data
  });
}
// 团队用户字典表（已经排除了已有团队的成员）
function groupUserDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/groupUserDictionary',
    method: 'get',
    data: data
  });
}
// 创建团队
function createGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/createGroup',
    method: 'post',
    data: data
  });
}
// 根据团队id查询团队详情
function getSysGroupDetailByGroupId(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/getSysGroupDetailByGroupId?groupId=' + data,
    method: 'get'
  });
}
// 修改团队
function updateGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/updateGroup',
    method: 'post',
    data: data
  });
}
// 解散团队
function removeGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/removeGroup?groupId=' + data,
    method: 'get'
  });
}

/**
 * @OA绩效管理
 * kadiam 2023-1-10
*/
// 根据groupId查询团队负责人绩效规则
function getGroupPerformanceRuleByGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/getGroupPerformanceRuleByGroup?groupId=' + data,
    method: 'get'
  });
}
// 更新团队负责人的绩效规则
function updateGroupPerformanceRule(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/updateGroupPerformanceRule',
    method: 'post',
    data: data
  });
}
// 根据groupId查询团队成员绩效规则
function getGroupMemberPerformanceRuleByGroupId(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupMember/getGroupMemberPerformanceRuleByGroupId?groupId=' + data,
    method: 'get'
  });
}
// 更新团队成员绩效规则
function updateGroupMemberPerformanceRule(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupMember/updateGroupMemberPerformanceRule',
    method: 'post',
    data: data
  });
}
// 根据月份查询团队经理绩效
function getGroupLeadPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/getGroupLeadPerformance',
    method: 'post',
    data: data
  });
}
// 结算团队经理本月绩效
function addGroupLeadPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/addGroupLeadPerformance',
    method: 'post',
    data: data
  });
}
// 查询团队内部成员
function groupDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/groupDictionary?groupId=' + data,
    method: 'get'
  });
}
// 根据月份查询团队成员绩效
function getGroupMemberPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/getGroupMemberPerformance',
    method: 'post',
    data: data
  });
}
// 结算团队成员本月绩效
function addGroupMemberPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/addGroupMemberPerformance',
    method: 'post',
    data: data
  });
}

// 根据月份查询团队经理绩效分页列表 1
// 根据月份查询团队成员绩效分页列表 2
function getGroupPerformancePage(data, type) {
  return (0, _bossRequest.default)({
    url: type === '1' ? 'sys/groupPerformance/getGroupLeadPerformancePage' : 'sys/groupPerformance/getGroupMemberPerformancePage',
    method: 'post',
    data: data
  });
}
// 根据月份查询团队经理绩效分页列表导出 1
// 根据月份查询团队成员绩效分页列表导出 2
function exportGroupLeadPerformance(data, type) {
  return (0, _bossRequest.default)({
    url: type === '1' ? 'sys/groupPerformance/exportGroupLeadPerformance' : 'sys/groupPerformance/exportGroupMemberPerformance',
    method: 'post',
    data: data
  });
}

// 结算团队拓展
function extendDetailPage(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/extendDetailPage' : 'sys/groupDetailPerformance/memberExtendDetailPage',
    method: 'post',
    data: data
  });
}
// 结算团队维护
function managerDetailPage(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/managerDetailPage' : 'sys/groupDetailPerformance/memberManagerDetailPage',
    method: 'post',
    data: data
  });
}
// 结算团队拓展 导出
function exportExtendDetail(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/exportExtendDetail' : 'sys/groupDetailPerformance/exportMemberExtendDetail',
    method: 'post',
    data: data
  });
}
// 结算团队维护 导出
function exportManagerDetail(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/exportManagerDetail' : 'sys/groupDetailPerformance/exportMemberManagerDetail',
    method: 'post',
    data: data
  });
}
// 业绩提成订单详情
function groupCommissionDetailPage(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupDetailPerformance/groupCommissionDetailPage',
    method: 'post',
    data: data
  });
}
// 业绩提成订单详情 导出
function exportGroupCommissionDetail(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupDetailPerformance/exportGroupCommissionDetail',
    method: 'post',
    data: data
  });
}

// 维护销售统计表
function maintainSalesStatistics(data) {
  return (0, _bossRequest.default)({
    url: 'receivable/maintainSalesStatistics',
    method: 'post',
    data: data
  });
}

// 维护销售统计表 导出
function exportMaintainSalesStatistics(data) {
  return (0, _bossRequest.default)({
    url: 'receivable/exportMaintainSalesStatistics',
    method: 'post',
    data: data
  });
}