"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadFile = UploadFile;
exports.UploadFileApi = UploadFileApi;
exports.largeFile_policy = largeFile_policy;
exports.largeFile_upload = largeFile_upload;
var _request = _interopRequireDefault(require("@/utils/request"));
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
var _bankendView = _interopRequireDefault(require("@/utils/bankendView"));
// export function UploadFileApi() {
//   return request({ url: 'hx.baoxian/common/oss/policy', method: 'GET' })
// }
function UploadFileApi(data) {
  if (!data) {
    data = '';
  }
  return (0, _bossRequest.default)({
    url: 'sysOss/policy?code=' + data,
    method: 'GET'
  });
}
function UploadFile(data) {
  return (0, _request.default)({
    url: 'hx.baoxian/common/upload/img',
    method: 'post',
    data: data
  });
}
// 大文件 oss 直传
function largeFile_upload(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/largeFile/upload',
    method: 'post',
    data: data
  });
}
// 大文件 oss 策略
function largeFile_policy(data) {
  return (0, _bankendView.default)({
    url: 'admin/sysOss/policy',
    method: 'GET'
  });
}