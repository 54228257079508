"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _equipment = require("@/api/equipment");
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'EffectiveAuto',
  model: {
    prop: 'autocompleteValue',
    event: 'handPro'
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入'
    },
    size: {
      type: String,
      default: ''
    },
    autocompleteValue: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      timeout: null,
      InfoList: [],
      autocompleteValue1: ''
    };
  },
  computed: {},
  watch: {
    autocompleteValue: {
      immediate: true,
      handler: function handler(val) {
        this.autocompleteValue1 = val;
        this.$emit('handPro', val);
      }
    },
    autocompleteValue1: function autocompleteValue1(val) {
      this.autocompleteValue1 = val;
    }
  },
  mounted: function mounted() {},
  methods: {
    focus: function focus() {
      var _this2 = this;
      setTimeout(function () {
        _this2.$refs.autoinput.focus();
      }, 50);
    },
    // 搜索结果加载
    querySearch: function querySearch(queryString, cb) {
      var _this3 = this;
      var _this = this;
      if (queryString && queryString.length > 1) {
        (0, _equipment.material_queryEffective)({
          page: 1,
          limit: 999,
          materialName: queryString
        }).then(function (res) {
          _this.InfoList = res.data.list;
          _this3.$emit('querySearchAsync', _this.InfoList);
        }).catch(function (err) {
          console.log(err);
        });
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          cb(_this.InfoList);
        }, 1000 * Math.random());
      } else {
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          cb([]);
        }, 30 * Math.random());
      }
    },
    select: function select(e) {
      // console.log('e', e, typeof e)
      this.autocompleteValue1 = e.name;
      this.$emit('handPro', e.name);
      this.$emit('select', e);
    },
    change: function change(e) {
      // console.log('e', e, typeof e)
      this.autocompleteValue1 = e;
      this.$emit('handPro', e);
      this.$emit('change', e);
    },
    clear: function clear(e) {
      // console.log('e', e, typeof e)
      this.autocompleteValue1 = '';
      this.$emit('handPro', e);
    },
    onFocus: function onFocus(e) {
      this.$emit('focus', e);
    },
    onBlur: function onBlur(e) {
      this.$emit('blur', e);
    }
  }
};