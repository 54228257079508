"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'CustomElDatePicker',
  model: {
    prop: 'timevalue',
    event: 'change'
  },
  props: {
    timevalue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    props: {
      type: Object,
      default: function _default() {
        return {
          label: 'name',
          value: 'id',
          checkStrictly: true
        };
      }
    },
    filterable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    collapseTags: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    clearable: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    isslot: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    showAllLevels: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      typeValue1: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)(['app'])),
  watch: {
    timevalue: {
      immediate: true,
      handler: function handler(val) {
        this.typeValue1 = val;
        this.$emit('change', val);
      }
    },
    typeValue1: {
      immediate: true,
      handler: function handler(newVal) {
        this.$emit('change', newVal);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    changeCascader: function changeCascader(e) {
      this.$emit('change', e);
    },
    clear: function clear(e) {
      this.$emit('change', e);
    }
  }
};