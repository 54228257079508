"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.random_string = exports.policy = exports.get_suffix = void 0;
exports.upProToOss = upProToOss;
require("core-js/modules/es6.function.name");
var _UploadFileApi = require("@/api/system/UploadFileApi");
var _axios = _interopRequireDefault(require("axios"));
/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
var get_suffix = exports.get_suffix = function get_suffix(filename) {
  var pos = filename.lastIndexOf('.');
  var suffix = '';
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};
var random_string = exports.random_string = function random_string(len) {
  len = len || 32;
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  var maxPos = chars.length;
  var pwd = '';
  for (var i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

/**
 * 通过policy协议
*/

var policy = exports.policy = function policy() {
  var pro = new Promise(function (resolve, rej) {
    // 判断签名有没有过期
    var res = JSON.parse(localStorage.getItem('sign'));
    var timestamp = Date.parse(new Date()) / 1000;
    if (res && res.expire - 3 > timestamp) {
      resolve(res);
    } else {
      (0, _UploadFileApi.largeFile_policy)().then(function (res) {
        console.log('UploadFileApi', res);
        if (res.code === 0) {
          localStorage.setItem('sign', JSON.stringify(res.data));
          resolve(res.data);
        }
      });
    }
  });
  return pro;
};

/**
 * 上传文件到oss
*/
function upProToOss(file, type) {
  var pro = new Promise(function (resolve, rej) {
    // 判断签名有没有过期
    var res = JSON.parse(localStorage.getItem('sign'));
    var timestamp = Date.parse(new Date()) / 1000;
    if (res && res.expire - 3 > timestamp) {
      resolve(res);
    } else {
      console.log('调用了');
      (0, _UploadFileApi.largeFile_policy)(type).then(function (res) {
        console.log('UploadFileApi', res);
        if (res.code === 0) {
          localStorage.setItem('sign', JSON.stringify(res.data));
          resolve(res.data);
        }
      });
    }
  });
  var imglist = new Promise(function (resolve, rej) {
    pro.then(function (success) {
      var data = success;
      var ossData = new FormData();
      console.log('file', file);
      ossData.append('name', file.name || file.file.name);
      // key就代表文件层级和阿里云上的文件名
      var filename = file.name || file.file.name;
      var picName = random_string(10) + get_suffix(filename);
      var keyValue = data.dir + picName;
      console.log('picName', picName);
      console.log('picName', keyValue);
      ossData.append('key', keyValue);
      ossData.append('policy', data.policy);
      ossData.append('OSSAccessKeyId', data.accessKeyId);
      ossData.append('success_action_status', 0);
      ossData.append('signature', data.signature);
      if (file.file) {
        ossData.append('file', file.file, file.file.name);
      } else {
        console.log('file', file);
        if (file.types === 'vedio') {
          ossData.append('file', file.raw, file.raw.name);
        } else {
          ossData.append('file', file, file.name);
        }
      }
      console.log('ossData', ossData);
      _axios.default.post(data.host, ossData).then(function (response) {
        var url = data.host + '/' + keyValue;
        console.log('uploadImage success is:', url);
        console.log('uploadImage response:', response);
        resolve(url);
      }).catch(function (err) {
        console.log(err);
      });
    });
  });
  return imglist;
}