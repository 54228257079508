"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("./index.scss");
var _echarts = _interopRequireDefault(require("echarts"));
var _equipment = require("@/api/equipment");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Dashboard',
  data: function data() {
    return {
      info01: {},
      info02: {},
      info03: {}
    };
  },
  mounted: function mounted() {
    this.drawChart1();
    this.drawChart2();
    this.drawChart3();
  },
  methods: {
    drawChart1: function drawChart1() {
      var _this = this;
      var chartDom = document.getElementById('main1');
      var myChart = _echarts.default.init(chartDom);
      var option;
      (0, _equipment.statistics_device)().then(function (res) {
        _this.info01 = res.data;
        option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
          },
          series: [{
            name: '设备数据',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 16,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [{
              value: res.data.mountCount,
              name: '挂载总数 ',
              itemStyle: {
                color: '#4F4FE6'
              }
            }, {
              value: res.data.freeCount,
              name: '空闲屏幕',
              itemStyle: {
                color: '#EAEDF2'
              }
            }]
          }]
        };
        option && myChart.setOption(option);
      });
    },
    drawChart2: function drawChart2() {
      var _this2 = this;
      var chartDom = document.getElementById('main2');
      var myChart = _echarts.default.init(chartDom);
      var option;
      (0, _equipment.statistics_resource)().then(function (res) {
        _this2.info02 = res.data;
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            }
          },
          dataset: {
            source: [['score', 'amount', 'product'], [100, res.data.screenList[1], '单屏'], [60, res.data.screenList[2], '两屏'], [10, res.data.screenList[3], '三屏']]
          },
          grid: {
            containLabel: true
          },
          xAxis: {
            name: 'amount'
          },
          yAxis: {
            type: 'category'
          },
          visualMap: {
            show: false,
            orient: 'horizontal',
            left: 'center',
            min: 10,
            max: 100,
            text: ['High Score', 'Low Score'],
            // Map the score column to color
            dimension: 0,
            inRange: {
              color: ['#5A7DFD', '#FF5D5E', '#9358F2']
            }
          },
          series: [{
            type: 'bar',
            encode: {
              x: 'amount',
              y: 'product'
            },
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }],
          legend: {
            show: true
          }
        };
        option && myChart.setOption(option);
      });
    },
    drawChart3: function drawChart3() {
      var _this3 = this;
      var chartDom = document.getElementById('main3');
      var myChart = _echarts.default.init(chartDom);
      var option;
      (0, _equipment.statistics_content)().then(function (res) {
        _this3.info03 = res.data;
        option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
          },
          series: [{
            name: '内容数据',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 16,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [{
              value: res.data.videoNum,
              name: '在播视频 ',
              itemStyle: {
                color: '#6582EF'
              }
            }, {
              value: res.data.picNum,
              name: '在播图片',
              itemStyle: {
                color: '#FF8D1A'
              }
            }]
          }]
        };
        option && myChart.setOption(option);
      });
    }
  }
};