var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    ref: "cas",
    attrs: {
      options: _vm.options,
      props: _vm.props,
      clearable: _vm.clearable,
      filterable: _vm.filterable,
      disabled: _vm.disabled,
      separator: " - ",
      "collapse-tags": _vm.collapseTags,
      "show-all-levels": _vm.showAllLevels,
      isslot: _vm.isslot,
    },
    on: { change: _vm.changeCascader, clear: _vm.clear },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var node = ref.node
            var data = ref.data
            return _vm.isslot
              ? [
                  _c("span", [
                    _vm._v(
                      _vm._s(data.mobile) + " -- " + _vm._s(data.storeName)
                    ),
                  ]),
                ]
              : undefined
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.typeValue1,
      callback: function ($$v) {
        _vm.typeValue1 = $$v
      },
      expression: "typeValue1",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }