"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.device_bind = device_bind;
exports.device_dealOnline = device_dealOnline;
exports.device_detail = device_detail;
exports.device_getApp = device_getApp;
exports.device_insert = device_insert;
exports.device_list = device_list;
exports.device_rebind = device_rebind;
exports.device_storeList = device_storeList;
exports.device_update = device_update;
exports.device_upgrate = device_upgrate;
exports.material_audit = material_audit;
exports.material_detail = material_detail;
exports.material_insert = material_insert;
exports.material_list = material_list;
exports.material_queryEffective = material_queryEffective;
exports.material_update = material_update;
exports.material_updateStatus = material_updateStatus;
exports.resource_broadcast = resource_broadcast;
exports.resource_broadcastList = resource_broadcastList;
exports.resource_copyMore = resource_copyMore;
exports.resource_copyOnly = resource_copyOnly;
exports.resource_delete = resource_delete;
exports.resource_deleteAll = resource_deleteAll;
exports.resource_deleteSelect = resource_deleteSelect;
exports.resource_detail = resource_detail;
exports.resource_insert = resource_insert;
exports.resource_list = resource_list;
exports.resource_queryForCopy = resource_queryForCopy;
exports.resource_storeList2 = resource_storeList2;
exports.resource_suspend = resource_suspend;
exports.resource_suspendAll = resource_suspendAll;
exports.resource_suspendMore = resource_suspendMore;
exports.resource_update = resource_update;
exports.statistics_content = statistics_content;
exports.statistics_device = statistics_device;
exports.statistics_resource = statistics_resource;
var _bankendView = _interopRequireDefault(require("@/utils/bankendView"));
/**
 * 设备管理
 * @kadiam 2023-05-19
 */
// 设备管理列表
function device_list(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/list',
    method: 'post',
    data: data
  });
}
// 添加设备
function device_insert(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/insert',
    method: 'post',
    data: data
  });
}
// 设备详情
function device_detail(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/detail/' + data,
    method: 'get'
  });
}
// 获取应用
function device_getApp(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/getApp/' + data,
    method: 'get'
  });
}
// 绑定设备
function device_bind(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/bind/' + data.deviceId + '/' + data.userId,
    method: 'get'
  });
}
// 解除绑定设备
function device_rebind(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/unbind/' + data,
    method: 'get'
  });
}
// 开关机管理
function device_dealOnline(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/dealOnline/' + data.deviceId + '/' + data.power,
    method: 'get'
  });
}
// 查询可选择门店列表
function device_storeList(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/storeList',
    method: 'post',
    data: data
  });
}
// 查询可选择门店列表 2
function resource_storeList2(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/queryStoreForCopy',
    method: 'post',
    data: data
  });
}
// 修改设备
function device_update(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/update',
    method: 'post',
    data: data
  });
}
// 修改设备
function device_upgrate(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/device/upgrate/' + data,
    method: 'get'
  });
}
/**
 * 播控管理
 * @kadiam 2023-05-19
 */
// 播控管理列表
function resource_list(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/list',
    method: 'post',
    data: data
  });
}
// 播控资源详情
function resource_broadcast(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/broadcast/' + data,
    method: 'get'
  });
}
// 资源详情
function resource_detail(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/detail/' + data,
    method: 'get'
  });
}
// 播放列表
function resource_broadcastList(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/broadcast',
    method: 'post',
    data: data
  });
}
// 删除资源
function resource_delete(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/delete/' + data,
    method: 'get'
  });
}
// 删除资源 （多选）
function resource_deleteSelect(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/deleteSelect',
    method: 'post',
    data: data
  });
}
// 删除全部资源
function resource_deleteAll(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/deleteAll/' + data,
    method: 'get'
  });
}
// 复制-查询可选择其他设备
function resource_queryForCopy(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/queryForCopy/' + data,
    method: 'get'
  });
}
// 复制资源 单个
function resource_copyOnly(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/copyOnly/' + data.resourceId + '/' + data.deviceId,
    method: 'get'
  });
}
// 复制资源 多个
function resource_copyMore(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/copyMore',
    method: 'post',
    data: data
  });
}
// 创建播放 广播 resourceType （0：图片，1：视频 2：广播）
function resource_insert(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/insert',
    method: 'post',
    data: data
  });
}
// 单个播放控制
function resource_suspend(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/suspend/' + data.resourceId + '/' + data.flag,
    method: 'get'
  });
}
// 全部播放控制（多选）
function resource_suspendMore(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/suspendMore',
    method: 'post',
    data: data
  });
}
// 全部播放控制
function resource_suspendAll(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/suspendAll/' + data.deviceId + '/' + data.flag,
    method: 'get'
  });
}
// 修改资源
function resource_update(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/resource/update',
    method: 'post',
    data: data
  });
}

/**
 * 素材管理
 * @kadiam 2023-05-22
 */
// 素材管理列表
function material_list(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/list',
    method: 'post',
    data: data
  });
}
// 查询可绑定的素材列表
function material_queryEffective(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/queryEffective',
    method: 'post',
    data: data
  });
}
// 增加素材
function material_insert(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/insert',
    method: 'post',
    data: data
  });
}
// 冻结素材
function material_updateStatus(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/updateStatus/' + data.materialId + '/' + data.status,
    method: 'get'
  });
}
// 素材详情
function material_detail(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/detail/' + data,
    method: 'get'
  });
}
// 修改素材
function material_update(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/update',
    method: 'post',
    data: data
  });
}
// 审核素材
function material_audit(data) {
  return (0, _bankendView.default)({
    url: 'vetsview/material/audit',
    method: 'post',
    data: data
  });
}

/**
 * 统计视图
 * @kadiam 2023-06-02
 */
// 设备统计
function statistics_device() {
  return (0, _bankendView.default)({
    url: 'vetsview/statistics/device',
    method: 'get'
  });
}
// 播控统计
function statistics_resource() {
  return (0, _bankendView.default)({
    url: 'vetsview/statistics/resource',
    method: 'get'
  });
}
// 内容统计
function statistics_content() {
  return (0, _bankendView.default)({
    url: 'vetsview/statistics/content',
    method: 'get'
  });
}