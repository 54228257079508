var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard_page" }, [
    _c("div", { staticClass: "top_info flex-r-sb-c" }, [
      _c(
        "div",
        { staticClass: "info_card" },
        [
          _c("div", { staticClass: "card_name" }, [_vm._v("设备数据")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", {
            staticStyle: { width: "400px", height: "200px" },
            attrs: { id: "main1" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card_tips" }, [
            _c(
              "span",
              {
                style: {
                  color:
                    _vm.info01.mountChangeFlag === 0 ? "#4CAE48" : "#D1531C",
                },
              },
              [
                _vm._v("挂载 "),
                _c("i", {
                  class:
                    _vm.info01.mountChangeFlag === 0
                      ? "el-icon-top"
                      : "el-icon-bottom",
                  staticStyle: { "font-weight": "bold" },
                }),
                _vm._v(_vm._s(_vm.info01.mountChangeCount) + "块 "),
              ]
            ),
            _vm._v(" "),
            _c("span", [_vm._v("同比上个月")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card_msg" }, [
            _c("div", { staticClass: "msg_name" }, [_vm._v("医院总数/家")]),
            _vm._v(" "),
            _c("div", { staticClass: "msg_num flex-r-c-c" }, [
              _vm._v(_vm._s(_vm.info01.companyCount) + " "),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "#333333",
                    "margin-left": "5px",
                  },
                },
                [_vm._v("家")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "msg_tips" }, [
              _c(
                "span",
                {
                  style: {
                    color:
                      _vm.info01.companyChangeCount === 0
                        ? "#4CAE48"
                        : "#D1531C",
                  },
                },
                [
                  _vm._v("覆盖 "),
                  _c("i", {
                    class:
                      _vm.info01.companyChangeFlag === 0
                        ? "el-icon-top"
                        : "el-icon-bottom",
                    staticStyle: { "font-weight": "bold" },
                  }),
                  _vm._v(_vm._s(_vm.info01.companyChangeCount) + "家 "),
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("同比上个月")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card_msg", staticStyle: { "margin-top": "10px" } },
            [
              _c("div", { staticClass: "msg_name" }, [_vm._v("开机/关机屏幕")]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_num flex-r-c-c" }, [
                _vm._v(
                  _vm._s(_vm.info01.powerOnCount) +
                    " | " +
                    _vm._s(_vm.info01.powerOffCount) +
                    " "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_tips" }, [
                _c(
                  "span",
                  {
                    style: {
                      color:
                        _vm.info01.powerOnChangeFlag === 0
                          ? "#4CAE48"
                          : "#D1531C",
                    },
                  },
                  [
                    _vm._v("开机 "),
                    _c("i", {
                      class:
                        _vm.info01.powerOnChangeFlag === 0
                          ? "el-icon-top"
                          : "el-icon-bottom",
                      staticStyle: { "font-weight": "bold" },
                    }),
                    _vm._v(_vm._s(_vm.info01.powerOnChangeCount) + "家 "),
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("同比上个月")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info_card" },
        [
          _c("div", { staticClass: "card_name" }, [_vm._v("屏控数据")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              width: "100%",
              height: "250px",
              "margin-top": "-25px",
            },
            attrs: { id: "main2" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card_msg", staticStyle: { "margin-top": "-25px" } },
            [
              _c("div", { staticClass: "msg_name" }, [
                _c("span", { staticStyle: { color: "#999999" } }, [
                  _vm._v("设备开机时长"),
                ]),
                _vm._v(" " + _vm._s(_vm.info02.powerDuration) + " "),
                _c("span", { staticStyle: { color: "#999999" } }, [
                  _vm._v("小时"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_num flex-r-c-c" }, [
                _vm._v(_vm._s(_vm.info02.monthPowerDuration) + " "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "#333333",
                      "margin-left": "5px",
                    },
                  },
                  [_vm._v("小时")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_tips" }, [
                _c(
                  "span",
                  {
                    style: {
                      color:
                        _vm.info02.powerChangeFlag === 0
                          ? "#4CAE48"
                          : "#D1531C",
                    },
                  },
                  [
                    _vm._v("开机 "),
                    _c("i", {
                      class:
                        _vm.info02.powerChangeFlag === 0
                          ? "el-icon-top"
                          : "el-icon-bottom",
                      staticStyle: { "font-weight": "bold" },
                    }),
                    _vm._v(_vm._s(_vm.info02.powerChangeDuration) + "小时 "),
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("同比上个月")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card_msg", staticStyle: { "margin-top": "10px" } },
            [
              _c("div", { staticClass: "msg_name" }, [
                _c("span", { staticStyle: { color: "#999999" } }, [
                  _vm._v("屏幕播放时长"),
                ]),
                _vm._v(" " + _vm._s(_vm.info02.playDuration) + " "),
                _c("span", { staticStyle: { color: "#999999" } }, [
                  _vm._v("小时"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_num flex-r-c-c" }, [
                _vm._v(_vm._s(_vm.info02.monthPlayDuration) + " "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "#333333",
                      "margin-left": "5px",
                    },
                  },
                  [_vm._v("小时")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_tips" }, [
                _c(
                  "span",
                  {
                    style: {
                      color:
                        _vm.info02.playChangeFlag === 0 ? "#4CAE48" : "#D1531C",
                    },
                  },
                  [
                    _vm._v("播放 "),
                    _c("i", {
                      class:
                        _vm.info02.playChangeFlag === 0
                          ? "el-icon-top"
                          : "el-icon-bottom",
                      staticStyle: { "font-weight": "bold" },
                    }),
                    _vm._v(_vm._s(_vm.info02.xxx) + "小时 "),
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("同比上个月")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info_card" },
        [
          _c("div", { staticClass: "card_name" }, [_vm._v("内容数据")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", {
            staticStyle: { width: "400px", height: "200px" },
            attrs: { id: "main3" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card_msg" }, [
            _c("div", { staticClass: "msg_name" }, [
              _c("span", { staticStyle: { color: "#999999" } }, [
                _vm._v("视频累计播放时长"),
              ]),
              _vm._v(" " + _vm._s(_vm.info03.videoDuration) + " "),
              _c("span", { staticStyle: { color: "#999999" } }, [
                _vm._v("小时"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "msg_num flex-r-c-c" }, [
              _vm._v(_vm._s(_vm.info03.monthVideoDuration) + " "),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "#333333",
                    "margin-left": "5px",
                  },
                },
                [_vm._v("小时")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card_msg", staticStyle: { "margin-top": "10px" } },
            [
              _c("div", { staticClass: "msg_name" }, [
                _c("span", { staticStyle: { color: "#999999" } }, [
                  _vm._v("图片累计播放时长"),
                ]),
                _vm._v(" " + _vm._s(_vm.info03.picDuration) + " "),
                _c("span", { staticStyle: { color: "#999999" } }, [
                  _vm._v("小时"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "msg_num flex-r-c-c" }, [
                _vm._v(_vm._s(_vm.info03.monthPicDuration) + " "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "#333333",
                      "margin-left": "5px",
                    },
                  },
                  [_vm._v("小时")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }