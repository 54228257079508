var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "common-table-filter-box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.searchForm, "label-width": "80px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 45 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入门店名称查询",
                            },
                            model: {
                              value: _vm.searchForm.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "companyName", $$v)
                              },
                              expression: "searchForm.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册账号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入注册账号查询",
                            },
                            model: {
                              value: _vm.searchForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "mobile", $$v)
                              },
                              expression: "searchForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备SN" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入设备SN码查询",
                            },
                            model: {
                              value: _vm.searchForm.snCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "snCode", $$v)
                              },
                              expression: "searchForm.snCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分屏类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.screenType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "screenType", $$v)
                                },
                                expression: "searchForm.screenType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "全屏", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "双屏A", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "双屏B", value: 5 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "双屏C", value: 6 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "三屏A", value: 2 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "三屏B", value: 3 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "三屏C", value: 4 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开机状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.online,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "online", $$v)
                                },
                                expression: "searchForm.online",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "开机", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "关机", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "播放状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.playStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "playStatus", $$v)
                                },
                                expression: "searchForm.playStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "播放", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "未播放", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备区域" } },
                        [
                          _c("common-cascader", {
                            attrs: {
                              options: _vm.app.district,
                              props: _vm.optionProps,
                            },
                            model: {
                              value: _vm.searchForm.district,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "district", $$v)
                              },
                              expression: "searchForm.district",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "绑定时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              editable: false,
                              type: "daterange",
                              align: "center",
                              "unlink-panels": "",
                              "range-separator": "→",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "value-format": "yyyy-MM-dd",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.time,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "time", $$v)
                              },
                              expression: "searchForm.time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onSearch()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.resetSearch()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-table-box pb-10" },
        [
          _c("div", { staticClass: "top-buttons-box flex-r-sb-c" }, [
            _c("div", { staticClass: "title" }, [_vm._v("设备管理")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-plus", plain: "" },
                    on: { click: _vm.openAdd },
                  },
                  [_vm._v("添加设备")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tabLoad,
                      expression: "tabLoad",
                    },
                  ],
                  ref: "commonElTable",
                  attrs: {
                    data: _vm.tableList,
                    "header-cell-class-name": "header-cell-class-name",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "snCode", label: "设备SN" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "companyName", label: "门店名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "mobile", label: "注册账号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "设备区域" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.provinceName) +
                                  " - " +
                                  _vm._s(scope.row.cityName) +
                                  " - " +
                                  _vm._s(scope.row.areaName) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分屏类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    [
                                      "全屏",
                                      "双屏A",
                                      "三屏A",
                                      "三屏B",
                                      "三屏C",
                                      "双屏B",
                                      "双屏C",
                                    ][scope.row.screenType]
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "开机状态",
                      align: "center",
                      width: "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    scope.row.online === 0
                                      ? "#3CBC67"
                                      : "#C73E12",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      ["已开机", "已关机", "已离线"][
                                        scope.row.online
                                      ]
                                    )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "播放状态",
                      align: "center",
                      width: "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    scope.row.playStatus === 0
                                      ? "#3CBC67"
                                      : "#C73E12",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      ["已播放", "未播放"][scope.row.playStatus]
                                    )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bindTime",
                      label: "绑定时间",
                      width: "160px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "right", width: "250px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.manager(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 管理设备 ")]
                                ),
                                _vm._v(" "),
                                scope.row.userId
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeBind(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 解绑门店 ")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.bind(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 绑定门店 ")]
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              align: "center",
              total: _vm.total,
              page: _vm.searchForm.page,
              limit: _vm.searchForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "limit", $event)
              },
              pagination: _vm.pagination,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_boundStore, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_boundStore = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("绑定门店")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.searchForm, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请输入医院名称/注册账号查询",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.remoteMethod,
                          },
                          model: {
                            value: _vm.userMobile,
                            callback: function ($$v) {
                              _vm.userMobile = $$v
                            },
                            expression: "userMobile",
                          },
                        },
                        _vm._l(_vm.userList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.mobile,
                              attrs: {
                                label: item.companyName,
                                value: item.userId,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(
                                  _vm._s(item.companyName) +
                                    "-" +
                                    _vm._s(item.mobile)
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.boundStore },
                    },
                    [_vm._v(" 绑定设备 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_addFacility, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_addFacility = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("添加设备")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.addForm, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备mac" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入mac码",
                            },
                            model: {
                              value: _vm.addForm.mac,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "mac", $$v)
                              },
                              expression: "addForm.mac",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备型号" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.addForm.deviceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "deviceType", $$v)
                                },
                                expression: "addForm.deviceType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "设备型号1", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "设备型号2", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备分组" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.addForm.deviceGroup,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "deviceGroup", $$v)
                                },
                                expression: "addForm.deviceGroup",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "设备分组1", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "设备分组2", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备描述" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              maxlength: "100",
                              "show-word-limit": "",
                              clearable: "",
                              placeholder: "请填写设备描述",
                            },
                            model: {
                              value: _vm.addForm.deviceDescription,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "deviceDescription", $$v)
                              },
                              expression: "addForm.deviceDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addFacility },
                    },
                    [_vm._v(" 保存设备 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_takeFacility, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_takeFacility = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("管理设备")]),
              ]),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  staticStyle: { height: "575px" },
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基础信息", name: "1" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticStyle: { "margin-left": "28px" },
                          attrs: { column: 1 },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "设备SN" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.snCode))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "设备型号" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.deviceType))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "设备分组" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.deviceGroup))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "入网时间" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.accessTime))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "屏幕类型" } },
                            [
                              _vm._v(
                                _vm._s(
                                  [
                                    "全屏",
                                    "双屏A",
                                    "三屏A",
                                    "三屏B",
                                    "三屏C",
                                    "双屏B",
                                    "双屏C",
                                  ][_vm.facilityInfo.screenType]
                                ) + "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "设备描述" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.facilityInfo.deviceDescription) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "固件版本" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.firmwareVersion))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "主板版本" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.boardVersion))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "物理地址" } },
                            [_vm._v(_vm._s(_vm.facilityInfo.mac))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "设备管理", name: "2" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticStyle: { "margin-left": "28px" },
                          attrs: { column: 1 },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "上电模式·当前状态" } },
                            [
                              parseInt(_vm.facilityInfo.online) === 0
                                ? _c("img", {
                                    staticStyle: {
                                      width: "58px",
                                      height: "22px",
                                    },
                                    attrs: {
                                      src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/open.png",
                                      mode: "",
                                    },
                                  })
                                : _c("img", {
                                    staticStyle: {
                                      width: "58px",
                                      height: "22px",
                                    },
                                    attrs: {
                                      src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/close.png",
                                      mode: "",
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "开机管理" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { icon: "el-icon-refresh-left" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeM(2)
                                    },
                                  },
                                },
                                [_vm._v("重启")]
                              ),
                              _vm._v(" "),
                              parseInt(_vm.facilityInfo.online) === 0
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-switch-button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeM(1)
                                          },
                                        },
                                      },
                                      [_vm._v("关机")]
                                    ),
                                  ]
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { icon: "el-icon-switch-button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeM(0)
                                        },
                                      },
                                    },
                                    [_vm._v("开机")]
                                  ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "开机管理" } },
                            [
                              _vm.update
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        plain: "",
                                        loading: "",
                                      },
                                    },
                                    [_vm._v("正在升级")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { icon: "el-icon-upload" },
                                      on: { click: _vm.updateM },
                                    },
                                    [_vm._v("开始升级")]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "应用管理", name: "3" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticStyle: { "margin-left": "28px" },
                          attrs: { column: 1, direction: "vertical" },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "预装应用/升级" } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: "20" } },
                                _vm._l(
                                  _vm.appList,
                                  function (soft, soft_index) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: soft_index,
                                        staticStyle: {
                                          "margin-bottom": "30px",
                                        },
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "soft_img flex-c-c-c",
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "60px",
                                                height: "60px",
                                              },
                                              attrs: {
                                                src:
                                                  "data:image/png;base64," +
                                                  soft.icon,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "soft_name" },
                                              [_vm._v(_vm._s(soft.packageName))]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-error soft_del",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "设备设置", name: "4" } },
                    [
                      _c(
                        "el-form",
                        {
                          staticStyle: { "margin-left": "28px" },
                          attrs: {
                            model: _vm.facilityInfo,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "facilitySet_title flex-r-s-s" },
                            [
                              _c("i", {
                                staticClass: "el-icon-caret-right icon1",
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-r-s-s" },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v("自动开机"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    staticClass: "mySwitch ",
                                    attrs: {
                                      "active-color": "#0768AB",
                                      "inactive-color": "#C5C5C5",
                                      "active-text": "开",
                                      "inactive-text": "关",
                                    },
                                    model: {
                                      value: _vm.facilityInfo.autoBootSwitch,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facilityInfo,
                                          "autoBootSwitch",
                                          $$v
                                        )
                                      },
                                      expression: "facilityInfo.autoBootSwitch",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "自动开机时间" } },
                            [
                              _c("el-time-picker", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  placeholder: "请选择开机时间",
                                },
                                model: {
                                  value: _vm.facilityInfo.autoBootTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facilityInfo,
                                      "autoBootTime",
                                      $$v
                                    )
                                  },
                                  expression: "facilityInfo.autoBootTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "facilitySet_title flex-r-s-s" },
                            [
                              _c("i", {
                                staticClass: "el-icon-switch-button icon2",
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-r-s-s" },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v("自动关机"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    staticClass: "mySwitch ",
                                    attrs: {
                                      "active-color": "#0768AB",
                                      "inactive-color": "#C5C5C5",
                                      "active-text": "开",
                                      "inactive-text": "关",
                                    },
                                    model: {
                                      value: _vm.facilityInfo.autoShutSwitch,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facilityInfo,
                                          "autoShutSwitch",
                                          $$v
                                        )
                                      },
                                      expression: "facilityInfo.autoShutSwitch",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "自动关机时间" } },
                            [
                              _c("el-time-picker", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  placeholder: "请选择关机时间",
                                },
                                model: {
                                  value: _vm.facilityInfo.autoShutTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facilityInfo,
                                      "autoShutTime",
                                      $$v
                                    )
                                  },
                                  expression: "facilityInfo.autoShutTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "facilitySet_title flex-r-s-s" },
                            [
                              _c("i", { staticClass: "el-icon-minus icon3" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-r-s-s" },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v("离线报警"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    staticClass: "mySwitch ",
                                    attrs: {
                                      "active-color": "#0768AB",
                                      "inactive-color": "#C5C5C5",
                                      "active-text": "开",
                                      "inactive-text": "关",
                                    },
                                    model: {
                                      value: _vm.facilityInfo.offlineSwitch,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facilityInfo,
                                          "offlineSwitch",
                                          $$v
                                        )
                                      },
                                      expression: "facilityInfo.offlineSwitch",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "离线时间" } },
                            [
                              _c("el-time-picker", {
                                staticStyle: { width: "80%" },
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.facilityInfo.offlineTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facilityInfo,
                                      "offlineTime",
                                      $$v
                                    )
                                  },
                                  expression: "facilityInfo.offlineTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "测试间隔" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "80%" },
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.facilityInfo.offlineInterval,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facilityInfo,
                                      "offlineInterval",
                                      $$v
                                    )
                                  },
                                  expression: "facilityInfo.offlineInterval",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeName === "4"
                ? _c(
                    "div",
                    { staticClass: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.changeFacility },
                        },
                        [_vm._v(" 修改设置 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }