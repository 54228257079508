var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "common-table-filter-box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.searchForm, "label-width": "80px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 45 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入素材名称查询",
                            },
                            model: {
                              value: _vm.searchForm.materialName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "materialName", $$v)
                              },
                              expression: "searchForm.materialName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材编号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入素材编号查询",
                            },
                            model: {
                              value: _vm.searchForm.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "id", $$v)
                              },
                              expression: "searchForm.id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "引用数量" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: {
                                placeholder: "请输入",
                                disabled: _vm.searchForm.compare === 0,
                              },
                              model: {
                                value: _vm.searchForm.compareNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "compareNum", $$v)
                                },
                                expression: "searchForm.compareNum",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.searchForm.compare,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "compare", $$v)
                                    },
                                    expression: "searchForm.compare",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "不限", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "小于", value: "<" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "等于", value: "=" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "大于", value: ">" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.materialStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchForm,
                                    "materialStatus",
                                    $$v
                                  )
                                },
                                expression: "searchForm.materialStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "待审核", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "正常", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "冻结", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.materialType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "materialType", $$v)
                                },
                                expression: "searchForm.materialType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "图片", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "视频", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开放类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.searchForm.openType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "openType", $$v)
                                },
                                expression: "searchForm.openType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "公共素材", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "运营素材", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "医院素材\t", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "添加时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              editable: false,
                              type: "daterange",
                              align: "center",
                              "unlink-panels": "",
                              "range-separator": "->",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "value-format": "yyyy-MM-dd",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.time,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "time", $$v)
                              },
                              expression: "searchForm.time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onSearch()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.resetSearch()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-table-box pb-10" },
        [
          _c("div", { staticClass: "top-buttons-box flex-r-sb-c" }, [
            _c("div", { staticClass: "title" }, [_vm._v("素材管理")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-plus", plain: "" },
                    on: { click: _vm.addMaterial },
                  },
                  [_vm._v("添加素材")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  ref: "commonElTable",
                  attrs: {
                    data: _vm.tableList,
                    "header-cell-class-name": "header-cell-class-name",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "素材编号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "materialName", label: "素材名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "quoteNum", label: "引用数量(台)" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "素材类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    ["图片", "视频"][scope.row.materialType]
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "开放类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    [
                                      "公共素材",
                                      "运营素材",
                                      "医院素材",
                                      "默认素材",
                                    ][scope.row.openType]
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "素材状态",
                      align: "center",
                      width: "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.materialStatus === 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      background: "#eb831b1a",
                                      color: "#ff7e0d",
                                      padding: "10px 3px",
                                    },
                                  },
                                  [_vm._v("待审")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.materialStatus === 1
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      background: "#3cbc671a",
                                      color: "#3CBC67",
                                      padding: "10px 3px",
                                    },
                                  },
                                  [_vm._v("正常")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.materialStatus === 2
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      background: "#d1531c1a",
                                      color: "#D1531C",
                                      padding: "10px 3px",
                                    },
                                  },
                                  [_vm._v("冻结")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "materialSource",
                      label: "素材来源",
                      align: "center",
                      width: "100px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "addTime",
                      label: "上传时间",
                      width: "160px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "right", width: "250px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                scope.row.materialStatus === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkMaterial(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 审核素材\n              ")]
                                    )
                                  : [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fraze(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.materialStatus === 1
                                                  ? "冻结"
                                                  : "解冻"
                                              ) +
                                              "素材\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editMaterial(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看素材 ")]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              align: "center",
              total: _vm.total,
              page: _vm.searchForm.page,
              limit: _vm.searchForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "limit", $event)
              },
              pagination: _vm.pagination,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_addMaterial, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_addMaterial = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm.addType === 1 ? "添加素材" : "素材详情") + " "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "addEditForm",
                  attrs: {
                    model: _vm.materialFrom,
                    rules: _vm.rules,
                    "label-width": "90px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材资源" } },
                        [
                          _vm.addType === 1
                            ? [
                                _vm.showDrag
                                  ? _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          drag: "",
                                          action: "",
                                          "http-request": _vm.picUpload,
                                          limit: 1,
                                          "before-upload": _vm.uploadBefore,
                                          "file-list": _vm.fileList,
                                          name: "file",
                                          multiple: false,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(
                                              "\n                  将文件拖到此处，或\n                  "
                                            ),
                                            _c("em", [_vm._v("点击上传")]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "show_source flex-r-s-c" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/backendView/icon_01.png",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "source_info" },
                                          [
                                            _c("div", { staticClass: "name" }, [
                                              _vm._v(_vm._s(_vm.ossFileName)),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "msg" }, [
                                              _vm._v(
                                                "文件大小: " +
                                                  _vm._s(_vm.ossFileSize) +
                                                  "M"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                _vm._v(" "),
                                !_vm.showDrag
                                  ? _c("el-progress", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        "text-inside": true,
                                        color: _vm.colors,
                                        "stroke-width": 20,
                                        percentage: _vm.progress.percentage,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : _c(
                                "div",
                                { staticClass: "show_source flex-r-s-c" },
                                [
                                  _vm.materialFrom.materialType === 1
                                    ? _c(
                                        "video",
                                        {
                                          ref: "music",
                                          staticClass: "video_class",
                                          attrs: {
                                            loop: "",
                                            controls: "",
                                            autoplay: false,
                                          },
                                        },
                                        [
                                          _c("source", {
                                            attrs: {
                                              src: _vm.materialFrom.fileUrl,
                                            },
                                          }),
                                        ]
                                      )
                                    : [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.materialFrom.fileUrl,
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "source_info" },
                                          [
                                            _c("div", { staticClass: "name" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.materialFrom.fileName
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材名称", prop: "materialName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "79%" },
                            attrs: {
                              clearable: "",
                              readonly: _vm.addType === 2,
                              placeholder: "请输入素材名称",
                            },
                            model: {
                              value: _vm.materialFrom.materialName,
                              callback: function ($$v) {
                                _vm.$set(_vm.materialFrom, "materialName", $$v)
                              },
                              expression: "materialFrom.materialName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.addType === 2
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "素材类型",
                                  prop: "materialType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.materialFrom.materialType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.materialFrom,
                                          "materialType",
                                          $$v
                                        )
                                      },
                                      expression: "materialFrom.materialType",
                                    },
                                  },
                                  [
                                    _vm.materialFrom.materialType === 0
                                      ? _c(
                                          "el-radio-button",
                                          { attrs: { label: 0 } },
                                          [_vm._v("图片素材")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.materialFrom.materialType === 1
                                      ? _c(
                                          "el-radio-button",
                                          { attrs: { label: 1 } },
                                          [_vm._v("视频素材")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "开放类型", prop: "openType" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.materialFrom.openType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.materialFrom,
                                          "openType",
                                          $$v
                                        )
                                      },
                                      expression: "materialFrom.openType",
                                    },
                                  },
                                  [
                                    _vm.materialFrom.openType === 0
                                      ? _c(
                                          "el-radio-button",
                                          { attrs: { label: 0 } },
                                          [_vm._v("公共素材")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.materialFrom.openType === 1
                                      ? _c(
                                          "el-radio-button",
                                          { attrs: { label: 1 } },
                                          [_vm._v("运营素材")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.materialFrom.openType === 2
                                      ? _c(
                                          "el-radio-button",
                                          { attrs: { label: 2 } },
                                          [_vm._v("医院素材")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.addType === 1
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "素材类型",
                                  prop: "materialType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.materialFrom.materialType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.materialFrom,
                                          "materialType",
                                          $$v
                                        )
                                      },
                                      expression: "materialFrom.materialType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 0 } },
                                      [_vm._v("图片素材")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 1 } },
                                      [_vm._v("视频素材")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "开放类型", prop: "openType" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.materialFrom.openType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.materialFrom,
                                          "openType",
                                          $$v
                                        )
                                      },
                                      expression: "materialFrom.openType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 0 } },
                                      [_vm._v("公共素材")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 1 } },
                                      [_vm._v("运营素材")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 2 } },
                                      [_vm._v("医院素材")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.addType === 2
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "subtitle",
                                staticStyle: { "margin-top": "44px" },
                              },
                              [_vm._v("审核记录")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "source_check_list" }, [
                              _c(
                                "div",
                                { staticClass: "list_item" },
                                [
                                  _c("div", { staticClass: "flex-r-sb-c" }, [
                                    _c("div", { staticClass: "name_status" }, [
                                      _vm._v(
                                        _vm._s(_vm.materialFrom.auditUserName) +
                                          " "
                                      ),
                                      _c("span", { staticClass: "status1" }, [
                                        _vm._v("【审核通过】"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "time" }, [
                                      _vm._v(
                                        _vm._s(_vm.materialFrom.auditTime)
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "remark" }, [
                                    _vm._v(
                                      "审核意见：" +
                                        _vm._s(_vm.materialFrom.auditApinion)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-divider"),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changeMaterial()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.addType === 1 ? "提交素材" : "提交修改") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "common-el-drawer",
        {
          attrs: { visible: _vm.drawer_checkMaterial, size: "610px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer_checkMaterial = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-detail-box" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("审核素材")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.materialFrom, "label-width": "90px" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-box description-box" },
                    [
                      _c("el-form-item", { attrs: { label: "素材资源" } }, [
                        _c(
                          "div",
                          { staticClass: "show_source flex-r-s-c" },
                          [
                            _vm.materialFrom.materialType === 1
                              ? _c(
                                  "video",
                                  {
                                    ref: "music",
                                    staticClass: "video_class",
                                    attrs: {
                                      loop: "",
                                      controls: "",
                                      autoplay: false,
                                    },
                                  },
                                  [
                                    _c("source", {
                                      attrs: { src: _vm.materialFrom.fileUrl },
                                    }),
                                  ]
                                )
                              : [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.materialFrom.fileUrl,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "source_info" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(_vm.materialFrom.fileName)),
                                    ]),
                                  ]),
                                ],
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "79%" },
                            attrs: {
                              readonly: "",
                              placeholder: "请输入素材名称",
                            },
                            model: {
                              value: _vm.materialFrom.materialName,
                              callback: function ($$v) {
                                _vm.$set(_vm.materialFrom, "materialName", $$v)
                              },
                              expression: "materialFrom.materialName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.materialFrom.materialType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.materialFrom,
                                    "materialType",
                                    $$v
                                  )
                                },
                                expression: "materialFrom.materialType",
                              },
                            },
                            [
                              _vm.materialFrom.materialType === 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("图片素材")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.materialFrom.materialType === 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("视频素材")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开放类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.materialFrom.openType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.materialFrom, "openType", $$v)
                                },
                                expression: "materialFrom.openType",
                              },
                            },
                            [
                              _vm.materialFrom.openType === 0
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("公共素材")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.materialFrom.openType === 1
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("运营素材")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.materialFrom.openType === 2
                                ? _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_vm._v("医院素材")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "审核意见" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              clearable: "",
                              placeholder: "请输入审核意见",
                            },
                            model: {
                              value: _vm.materialFrom.auditOpinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.materialFrom, "auditOpinion", $$v)
                              },
                              expression: "materialFrom.auditOpinion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.checkM(1)
                        },
                      },
                    },
                    [_vm._v(" 通过审核 ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { background: "#D1531C" },
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.checkM(0)
                        },
                      },
                    },
                    [_vm._v(" 驳回素材 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }