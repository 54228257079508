"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.generaMenu = generaMenu;
var _objectSpread2 = _interopRequireDefault(require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router = require("@/router");
var _layout = _interopRequireDefault(require("@/layout"));
var _user = require("@/api/user");
// import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routes
 */
function generaMenu(routes, data) {
  data.forEach(function (item) {
    var menu;
    if (item.url === '/equipment') {
      menu = {
        path: '/equipment',
        component: _layout.default,
        redirect: '/equipment/equipment',
        children: [{
          path: 'equipment',
          component: function component() {
            return Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/views/equipment/equipment'));
            });
          },
          name: 'equipment',
          meta: {
            title: '设备管理',
            icon: 'equipment',
            noCache: true
          }
        }]
      };
    } else if (item.url === '/broadcas') {
      menu = {
        path: '/broadcas',
        component: _layout.default,
        redirect: '/broadcas/broadcas',
        children: [{
          path: 'broadcas',
          component: function component() {
            return Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/views/broadcas/broadcas'));
            });
          },
          name: 'broadcas',
          meta: {
            title: '播控管理',
            icon: 'broadcas',
            noCache: true
          }
        }, {
          path: 'playManager',
          component: function component() {
            return Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/views/broadcas/playManager'));
            });
          },
          hidden: true,
          name: 'playManager',
          meta: {
            title: '播放管理',
            icon: 'broadcas',
            noCache: true
          }
        }]
      };
    } else if (item.url === '/material') {
      menu = {
        path: '/material',
        component: _layout.default,
        redirect: '/material/material',
        children: [{
          path: 'material',
          component: function component() {
            return Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/views/material/material'));
            });
          },
          name: 'material',
          meta: {
            title: '素材管理',
            icon: 'material',
            noCache: true
          }
        }]
      };
    } else {
      menu = {
        path: item.url,
        component: item.type === 0 ? _layout.default : function (resolve) {
          return require(["@/views/".concat(item.url, ".vue")], resolve);
        },
        // hidden: true,
        children: [],
        name: item.name,
        hidden: item.status !== 1,
        meta: {
          title: item.name,
          id: item.menu_id,
          roles: ['admin'],
          icon: item.icon
        }
      };
      if (item.list) {
        generaMenu(menu.children, item.list);
      }
    }
    routes.push(menu);
  });
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var _this = this;
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var loadMenuData = [];
      // 先查询后台并返回左侧菜单数据并把数据添加到路由
      (0, _user.getMenu)().then(function (res) {
        var data = res;
        sessionStorage.setItem('permissions', JSON.stringify(res.data.permissions || '[]'));
        sessionStorage.setItem('orgList', JSON.stringify(res.data.orgList || '[]'));
        if (res.code !== 0) {
          _this.$message({
            message: '菜单数据加载异常',
            type: 0
          });
        } else {
          data = res.data.menuList;
          Object.assign(loadMenuData, data);
          generaMenu(_router.asyncRoutes, loadMenuData);
          var accessedRoutes;
          if (roles.roles.includes('admin')) {
            // alert(JSON.stringify(asyncRoutes))
            accessedRoutes = _router.asyncRoutes || [];
          } else {
            accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
            // accessedRoutes = asyncRoutes || []
          }
          commit('SET_ROUTES', accessedRoutes);
          resolve(accessedRoutes);
        }
        // generaMenu(asyncRoutes, data)
      }).catch(function (error) {
        console.log(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};