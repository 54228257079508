"use strict";

var _interopRequireDefault = require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/home/devops/repos/36_20240910135548/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("./index.scss");
var _vuex = require("vuex");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _CommonCascader = _interopRequireDefault(require("@/components/CommonCascader"));
var _CommonElDrawer = _interopRequireDefault(require("@/components/CommonElDrawer"));
var _equipment = require("@/api/equipment");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'BroadcasIndex',
  components: {
    commonCascader: _CommonCascader.default,
    pagination: _Pagination.default,
    commonElDrawer: _CommonElDrawer.default
  },
  data: function data() {
    return {
      searchForm: {
        page: 1,
        limit: 10,
        select1: 'all',
        select2: 'all',
        select3: 'all'
      },
      optionProps: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      tabLoad: false,
      tableList: [],
      total: 0,
      drawer_screenManager: false,
      mId: '',
      screen: {
        type: '全屏',
        index: 1
      },
      smallList: [{
        index: 1,
        name: '全屏',
        check: true
      }, {
        index: 2,
        name: '双屏-A',
        check: false
      }, {
        index: 6,
        name: '双屏-B',
        check: false
      }, {
        index: 7,
        name: '双屏-C',
        check: false
      }, {
        index: 3,
        name: '三屏-A',
        check: false
      }, {
        index: 4,
        name: '三屏-B',
        check: false
      }, {
        index: 5,
        name: '三屏-C',
        check: false
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)(['app', 'user'])),
  created: function created() {
    this.$store.dispatch('app/setdistrict'); // 地址获取
    var key = this.$options.name + 'SearchFormFrontend';
    if (sessionStorage.getItem(key)) {
      this.searchForm = JSON.parse(sessionStorage.getItem(key));
    }
    this.onSearch();
  },
  methods: {
    onSearch: function onSearch() {
      var _this = this;
      sessionStorage.setItem(this.$options.name + 'SearchFormFrontend', JSON.stringify(this.searchForm));
      this.tabLoad = true;
      // 处理地区
      this.searchForm.areaId = this.searchForm.cityId = this.searchForm.provinceId = '';
      if (this.searchForm.district) {
        if (this.searchForm.district.length > 0) this.searchForm.provinceId = this.searchForm.district[0];
        if (this.searchForm.district.length > 1) this.searchForm.cityId = this.searchForm.district[1];
        if (this.searchForm.district.length > 2) this.searchForm.areaId = this.searchForm.district[2];
      }
      // 处理时间
      this.searchForm.startTime = this.searchForm.endTime = '';
      if (this.searchForm.time) {
        this.searchForm.startTime = this.searchForm.time[0];
        this.searchForm.endTime = this.searchForm.time[1];
      }
      (0, _equipment.resource_list)(this.searchForm).then(function (res) {
        _this.tableList = res.data.list;
        _this.total = parseInt(res.data.total);
        _this.tabLoad = false;
      }).catch(function () {
        _this.tabLoad = false;
      });
    },
    resetSearch: function resetSearch() {
      this.searchForm = {
        page: 1,
        limit: 10,
        screenType: '',
        online: '',
        playStatus: ''
      };
      this.onSearch();
    },
    pagination: function pagination(e) {
      this.searchForm.page = e.page;
      this.searchForm.limit = e.limit;
      this.onSearch();
    },
    // 屏幕管理
    screenManager: function screenManager(item) {
      this.mId = item.id;
      this.screen.index = item.screenType * 1 + 1;
      for (var i in this.smallList) {
        if (this.smallList[i].index === this.screen.index) {
          this.smallList[i].check = true;
          this.screen.type = this.smallList[i].name;
        } else {
          this.smallList[i].check = false;
        }
      }
      this.drawer_screenManager = true;
    },
    chooseScreen: function chooseScreen(e) {
      this.screen.type = e.name;
      this.screen.index = e.index;
      for (var i in this.smallList) {
        if (this.smallList[i].index === e.index) {
          this.smallList[i].check = true;
        } else {
          this.smallList[i].check = false;
        }
      }
    },
    save: function save() {
      var _this2 = this;
      var parms = {
        id: this.mId,
        screenType: this.screen.index * 1 - 1
      };
      (0, _equipment.device_update)(parms).then(function (res) {
        _this2.drawer_screenManager = false;
        _this2.$message.success('操作成功');
        _this2.onSearch();
      });
    },
    // 播放管理
    mangerPlay: function mangerPlay(item) {
      this.$router.push({
        path: 'playManager',
        query: {
          id: item.id
        }
      });
    }
  }
};